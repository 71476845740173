// @flow
export const isEmpty = (input: string): boolean =>
  !input || typeof input !== 'string' || !input.trim().length;

const EMPTY_MESSAGE = '必須項目です';
// const SELECT_EMPTY_MESSAGE = '選択してください';
const MAX_AGE = 130;

const compareDate = (
  y1: number,
  m1: number,
  d1: number,
  y2: number,
  m2: number,
  d2: number
): boolean =>
  y1 < y2 || (y1 === y2 && m1 < m2) || (y1 === y2 && m1 === m2 && d1 <= d2);

const validators = {
  email: (email: string) => {
    const re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

    if (isEmpty(email)) {
      return EMPTY_MESSAGE;
    }

    if (!re.test(email)) {
      return 'メールアドレスを正しく入力してください';
    }

    return null;
  },
  phone: (phone: string) => {
    if (isEmpty(phone)) {
      return EMPTY_MESSAGE;
    }

    if (!/^\d+$/.test(phone)) {
      return '半角数字で入力してください';
    }

    // only accepts mobile, non-virtual phone numbers
    if (!/^(819|818|817|09|08|07)/.test(phone)) {
      return 'この番号はご利用いただけません';
    }

    return null;
  },
  number: (number: string) => {
    if (isEmpty(number)) {
      return EMPTY_MESSAGE;
    }

    if (!/^\d+$/.test(number)) {
      return '半角数字で入力してください';
    }

    return null;
  },
  zip: (zip: string) => {
    if (isEmpty(zip)) {
      return EMPTY_MESSAGE;
    }

    if (!/^\d{7}$/.test(zip)) {
      return '郵便番号に誤りがあります';
    }

    return null;
  },
  required: (input: string) => {
    if (isEmpty(input)) {
      return EMPTY_MESSAGE;
    }

    return null;
  },
  dateOfBirth: (dateOfBirth: string) => {
    const daysInMonth = ({ year, month }: { year: number, month: number }) =>
      new Date(year, month, 0).getDate();
    const reDateOfBirth = /^\d{4}-\d{2}-\d{2}$/;

    if (!reDateOfBirth.test(dateOfBirth)) {
      return '生年月日を正しく入力してください';
    }

    // at this point we know the format is valid
    const [year, month, date] = dateOfBirth.split('-').map((v) => +v);

    if (daysInMonth({ year, month }) < date) {
      return '生年月日を正しく入力してください';
    }

    const now = new Date();
    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    const today = now.getDate();

    if (
      compareDate(year + MAX_AGE, month, date, thisYear, thisMonth, today) ||
      compareDate(thisYear, thisMonth, today, year, month, date)
    ) {
      return '入力された生年月日ではお申込みいただけません';
    }

    return null;
  },
  katakana: (katakana: string) => {
    if (isEmpty(katakana)) {
      return EMPTY_MESSAGE;
    }

    /**
     * 1. Consumer must be able to enter hankaku or zenkaku.
     * 2. Kana name can contain katakana and the  “ー”.
     * 3. Alphabetic characters are "not" allowed.
     * regex ref: https://gist.github.com/oanhnn/9043867
     */
    if (!/^([\s ァ-ンｧ-ﾝﾞﾟ]|ー)+$/.test(katakana)) {
      return 'カタカナで入力してください';
    }

    return null;
  },
  kanji: (kanji: string) => {
    if (isEmpty(kanji)) {
      return EMPTY_MESSAGE;
    }

    /**
     * 1. Kanji name can contain kanji, hiragana, katakana, alphabet and the “-” or “ー”.
     * 2. Consumer must be able to enter hankaku or zenkaku.
     * 3. Alphabetic characters are allowed.
     * regex ref: https://gist.github.com/oanhnn/9043867
     */
    if (
      !/^([ぁ-んァ-ンｧ-ﾝﾞﾟ一-龥A-Za-ｚ]|[\u4E00-\u9FAF]|-|ー)+$/.test(kanji)
    ) {
      return '数字や記号 (%,*,#) は入力できません';
    }

    return null;
  },
  if: (tester: Function, validator: Function) => {
    return (_: String, props: Object) => {
      if (tester(_, props)) {
        return validator;
      }

      return null;
    };
  },
};

export default validators;
