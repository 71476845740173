// @flow
import { useState } from 'react';

const useValidation = ({
  validations,
  values,
}: {
  validations: Object,
  values: Object,
}): Object => {
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validate = (
    { checkTouch }: { checkTouch?: boolean } = { checkTouch: false }
  ): Object => {
    const _errors: Object = {};
    const _touched: Object = { ...touched };

    Object.keys(validations).forEach((key: string) => {
      if (checkTouch) {
        if (!touched[key]) {
          return;
        }
      } else {
        _touched[key] = true;
      }

      const validator = validations[key];
      const value = values[key];

      _errors[key] = validator(value, values);
    });

    setErrors(_errors);
    setTouched(_touched);

    return _errors;
  };

  const onValidate = (
    {
      name,
      value,
      checkTouch,
    }: { name: string, value: string, checkTouch: boolean } = {
      name: '',
      value: '',
      checkTouch: true,
    }
  ) => {
    const key = name;
    const _errors: Object = { ...errors };
    const _touched: Object = { ...touched };

    if (checkTouch) {
      if (!touched[key]) {
        return;
      }
    } else {
      _touched[key] = true;
      setTouched(_touched);
    }

    const validator = validations[key];
    const _value = value !== null ? value : values[key];

    const _error = validator(_value, values);

    if (_errors[key] !== _error) {
      _errors[key] = _error;

      setErrors(_errors);
    }
  };

  return {
    onValidateAll: validate,
    onValidate,
    errors,
    hasError: !!Object.keys(errors).find((key) => !!errors[key]),
  };
};

export default useValidation;
