// @flow
import React from 'react';
import TabsWrapper from '../../../../components/shared/Tabs/TabsWrapper';
import Tab from '../../../../components/shared/Tabs/Tab';
import { navigationStyles } from '../styles';
import { smoothScrollToElement } from '../../../../utils';

const Navigation = ({
  location,
  blocks,
}: {
  location: Object,
  blocks: Array<Object>,
}) => {
  const classes = navigationStyles({});

  if (location.hash) {
    smoothScrollToElement(location.hash, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -187,
    });
  }

  return (
    <section className={classes.navSec}>
      <TabsWrapper full>
        {blocks.map((block) => (
          <Tab full key={block.id} id={block.id} pillColor="secondary">
            {block.title}
          </Tab>
        ))}
      </TabsWrapper>
    </section>
  );
};

Navigation.defaultProps = {
  blocks: [],
};

export default Navigation;
