// @flow
//Core
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import cx from 'classnames';
//Local
import { previewStyles } from '../styles';
import typography from '../../../../styles/typography.module.scss';

const PreviewSec = ({
  title,
  subtitle,
  image,
}: {
  title: string,
  subtitle: string,
  image: string,
}) => {
  const styles = previewStyles();

  return (
    <section
      className={styles.previewSec}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h2"
          className={cx(typography.withDividerPurple, styles.title)}
        >
          {title}
        </Typography>
        <Typography variant="body2" className={styles.subtitle}>
          {subtitle}
        </Typography>
      </Container>
    </section>
  );
};

export default PreviewSec;
