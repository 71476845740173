// @flow
//Core
import React from 'react';
import get from 'lodash.get';
import { Container, Typography, Box } from '@material-ui/core';
import { graphql } from 'gatsby';
//Local
import { Layout } from '../../../components';
import {
  PreviewSec,
  NavigationSec,
  ItemSec,
  FormSec,
} from '../../../components/mediaCenterSections/pressKit';

export const query = graphql`
  {
    contentfulPagePressKit {
      pageTitle
      pageSubtitle
      pageDescription
      pageBackground {
        file {
          url
        }
      }
      firstSectionTitle
      firstSectionDescription
      firstSectionImage {
        file {
          url
        }
      }
      firstSectionFile {
        file {
          url
        }
      }
      secondSectionTitle
      secondSectionDescription
      secondSectionImage {
        file {
          url
        }
      }
      secondSectionFile {
        file {
          url
        }
      }
      thirdSectionTitle
      thirdSectionDescription
      thirdSectionImage {
        file {
          url
        }
      }
      thirdSectionFile {
        file {
          url
        }
      }
      fourthSectionTitle
      fourthSectionDescription
    }
  }
`;

const PressKit = ({ location, data }: { location: Object, data: Object }) => {
  const navBlocks = [
    {
      id: 'firstSec',
      title: get(data, 'contentfulPagePressKit.firstSectionTitle'),
    },
    {
      id: 'secondSec',
      title: get(data, 'contentfulPagePressKit.secondSectionTitle'),
    },
    {
      id: 'thirdSec',
      title: get(data, 'contentfulPagePressKit.thirdSectionTitle'),
    },
    {
      id: 'fourthSec',
      title: get(data, 'contentfulPagePressKit.fourthSectionTitle'),
    },
  ];

  return (
    <Layout>
      <PreviewSec
        title={get(data, 'contentfulPagePressKit.pageTitle')}
        subtitle={get(data, 'contentfulPagePressKit.pageSubtitle')}
        image={get(data, 'contentfulPagePressKit.pageBackground.file.url')}
      />
      <NavigationSec blocks={navBlocks} location={location} />
      <Container maxWidth="md">
        <Box pt={9} pb={3}>
          <Typography variant="body1" component={Box}>
            {get(data, 'contentfulPagePressKit.pageDescription')}
          </Typography>
        </Box>
      </Container>
      <ItemSec
        title={get(data, 'contentfulPagePressKit.firstSectionTitle')}
        description={get(
          data,
          'contentfulPagePressKit.firstSectionDescription'
        )}
        image={get(data, 'contentfulPagePressKit.firstSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.firstSectionFile.file.url')}
        id={navBlocks[0].id}
      />
      <ItemSec
        title={get(data, 'contentfulPagePressKit.secondSectionTitle')}
        description={get(
          data,
          'contentfulPagePressKit.secondSectionDescription'
        )}
        image={get(data, 'contentfulPagePressKit.secondSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.secondSectionFile.file.url')}
        id={navBlocks[1].id}
      />
      <ItemSec
        title={get(data, 'contentfulPagePressKit.thirdSectionTitle')}
        description={get(
          data,
          'contentfulPagePressKit.thirdSectionDescription'
        )}
        image={get(data, 'contentfulPagePressKit.thirdSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.thirdSectionFile.file.url')}
        id={navBlocks[2].id}
      />
      <FormSec
        title={get(data, 'contentfulPagePressKit.fourthSectionTitle')}
        description={get(
          data,
          'contentfulPagePressKit.fourthSectionDescription'
        )}
        id={navBlocks[3].id}
        button="送信"
      />
    </Layout>
  );
};

export default PressKit;
