/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import icon from '../../../images/icon-checked-square.svg';

export const previewStyles = makeStyles(() => ({
  previewSec: {
    height: 460,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    textAlign: 'center',
    padding: '100px 0px 200px 0px',
    marginBottom: 50,
    '@media (max-width: 1024px)': {
      height: 350,
      padding: '50px 0px 100px 0px',
      marginBottom: 'initial',
    },
  },
  titel: {
    '@media (max-width: 1024px)': {
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  subtitle: {
    marginTop: 32,
    '@media (max-width: 1024px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export const navigationStyles = makeStyles(() => ({
  navSec: {
    position: 'sticky',
    top: 100,
    backgroundColor: '#fff',
    zIndex: 8,
    paddingTop: 24,
    '@media (max-width: 1024px)': {
      maxWidth: '100%',
      overflowX: 'scroll',
      top: 56,
      zIndex: 9,
    },
  },
}));

export const itemStyles = makeStyles(() => ({
  itemSec: {
    padding: '75px 0 75px 0',
    '@media (max-width: 1024px)': {
      padding: '35px 0 35px 0',
      textAlign: 'center',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 100,
    '@media (max-width: 1024px)': {
      marginBottom: 64,
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  description: {
    marginBottom: 70,
    '@media (max-width: 1024px)': {
      marginBottom: 50,
      textAlign: 'left',
    },
  },
  image: {
    width: '100%',
    '@media (max-width: 1024px)': {
      marginTop: 50,
    },
  },
}));

export const formStyles = makeStyles((theme) => ({
  formSec: {
    padding: '75px 0 150px 0',
    '@media (max-width: 1024px)': {
      padding: '100px 0 120px 0',
    },
  },
  title: {
    marginBottom: 73,
    textAlign: 'center',
    '@media (max-width: 1024px)': {
      marginBottom: 50,
      fontSize: '28px',
      lineHeight: '40px',
    },
  },
  description: {
    marginBottom: 90,
    '@media (max-width: 1024px)': {
      marginBottom: 50,
    },
  },
  submitBtn: {
    minWidth: 250,
  },
  input: {
    '@media (max-width: 1024px)': {
      padding: '0!important',
      backgroundColor: '#fff',
    },
    '& * input': {
      marginTop: 10,
      '@media (max-width: 1024px)': {
        backgroundColor: '#fff',
        borderBottom: '1px solid #8D96A2!important',
      },
    },
    '& * div': {
      '@media (max-width: 1024px)': {
        marginTop: 0,
      },
    },
  },
  white: {
    '& input': {
      backgroundColor: '#fff !important',
    },
    '& textarea': {
      backgroundColor: '#fff !important',
    },
  },
  icon: {
    border: '2px solid #101825',
    background: '#fff',
    borderRadius: '2px',
    width: 18,
    height: 18,
  },
  checkedIcon: {
    background: `url(${icon}) center no-repeat`,
    backgroundSize: 'cover',
    border: 'none',
  },
  container: {
    paddingTop: '64px',
    '@media (max-width: 1024px)': {
      paddingTop: 0,
    },
  },
  textArea: {
    marginTop: 11,
    background: '#FFF',
    border: '1px solid #8D96A2',
    borderRadius: '8px',
    resize: 'none',
    font: `300 14px/24px ${theme.typography.fontFamily}`,
    marginBottom: 3,
    height: '8em!important',
  },
}));
