// @flow
//Core
import React from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
//Local
import { itemStyles } from '../styles';

const CardsSetSec = ({
  title,
  description,
  image,
  id,
  file,
}: {
  title: string,
  description: string,
  image: string,
  id: string,
  file: string,
}) => {
  const styles = itemStyles();

  return (
    <section className={styles.itemSec} id={id}>
      <Container maxWidth="md">
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Button
              variant="outlined"
              color="primary"
              component="a"
              target="_blank"
              rel="noreferrer noopener"
              href={file}
            >
              ダウンロードはこちら
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={image} alt="" className={styles.image} />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default CardsSetSec;
